import { DownloadContent, DownloadContents } from "../generated";
import { IAsset } from "../models/asset";
import { IError } from "../models/error";
import { ViewType } from "../models/viewType";

export enum ActionType {
  Reset,
  SetError,
  SetAsset,
  SetPageLoading,
  SetDownloadContents,
  SetDownloadView,
  SetPageNumber,
  SetPageSize,
  SelectComponent,
  SelectAllComponents
}

export interface IReset {
  type: ActionType.Reset;
  payload: null;
}

export interface ISetError {
  type: ActionType.SetError;
  payload?: IError;
}

export interface ISetAsset {
  type: ActionType.SetAsset;
  payload: IAsset;
}

export interface ISetPageLoading {
  type: ActionType.SetPageLoading;
  payload: boolean;
}

export interface ISetDownloadContents {
  type: ActionType.SetDownloadContents;
  payload: {
    preservicaId: string,
    downloadContents: DownloadContents
  };
}

export interface ISetDownloadView {
  type: ActionType.SetDownloadView;
  payload: ViewType;
}

export interface ISetPageNumber {
  type: ActionType.SetPageNumber;
  payload: number;
}

export interface ISetPageSize {
  type: ActionType.SetPageSize;
  payload: number;
}

export interface ISelectComponent {
  type: ActionType.SelectComponent;
  payload: DownloadContent;
}

export interface ISelectAllComponents {
  type: ActionType.SelectAllComponents;
  payload: null;
}

export type AppActions =
  IReset |
  ISetError |
  ISetAsset |
  ISetPageLoading |
  ISetDownloadContents |
  ISetDownloadView |
  ISetPageNumber |
  ISetPageSize |
  ISelectComponent |
  ISelectAllComponents;

export const reset = (): IReset => ({
  type: ActionType.Reset,
  payload: null
});

export const setError = (reason?: any): ISetError => ({
  type: ActionType.SetError,
  payload: reason ? {message: "Something went wrong", apiReason: reason} : undefined
});

export const setAsset = (asset: IAsset): ISetAsset => ({
  type: ActionType.SetAsset,
  payload: asset
});

export const setPageLoading = (): ISetPageLoading => ({
  type: ActionType.SetPageLoading,
  payload: true
});

export const setPageLoaded = (): ISetPageLoading => ({
  type: ActionType.SetPageLoading,
  payload: false
});

export const setDownloadContents = (preservicaId: string, downloadContents: DownloadContents): ISetDownloadContents => ({
  type: ActionType.SetDownloadContents,
  payload: {
    preservicaId: preservicaId,
    downloadContents: downloadContents
  }
});

export const setDownloadView = (viewType: ViewType): ISetDownloadView => ({
  type: ActionType.SetDownloadView,
  payload: viewType
});

export const setPageNumber = (pageNumber: number): ISetPageNumber => ({
  type: ActionType.SetPageNumber,
  payload: pageNumber
});

export const setPageSize = (pageSize: number): ISetPageSize => ({
  type: ActionType.SetPageSize,
  payload: pageSize
});

export const selectComponent = (component: DownloadContent): ISelectComponent => ({
  type: ActionType.SelectComponent,
  payload: component
});

export const selectAllComponents = (): ISelectAllComponents => ({
  type: ActionType.SelectAllComponents,
  payload: null
});