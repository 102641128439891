import React from "react";
import styles from "./BrowserPivot.module.css";
import { Pivot, PivotItem } from "@fluentui/react";
import Title from "../title/Title";
import { useNavigate, useParams } from "react-router-dom";
import { LogService } from "@preservica/log-service";

const LOG_SOURCE = "BrowserPivot";
const BrowserPivot: React.FunctionComponent = () => {
  const { preservicaId } = useParams();
  const navigate = useNavigate();
  const downloadKey = "Download";
  const viewKey = "View";

  const _onLinkClick = (item?: PivotItem): void => {
    const LOG_MESSAGE = "_onLinkClick";
    LogService.verbose(LOG_SOURCE, LOG_MESSAGE, "item", item);
    if (item && item.props && item.props.itemKey !== _selectedPivot()) {
      let href;
      if (item.props.itemKey === downloadKey) {
        href = `/${downloadKey.toLocaleLowerCase()}/${preservicaId}`;
      } else {
        href = `/${viewKey.toLocaleLowerCase()}/${preservicaId}`;
      }
      LogService.info(LOG_SOURCE, LOG_MESSAGE, "href", href);
      navigate(href);
    }
  };

  const _selectedPivot = (): string => {
    const selectedPivot = window.location.pathname
      .toLocaleLowerCase()
      .startsWith(`/${downloadKey.toLocaleLowerCase()}/`)
      ? downloadKey
      : viewKey;
    LogService.verbose(LOG_SOURCE, "selectedPivot", selectedPivot);
    return selectedPivot;
  };

  return (
    <div className={styles.Pivot} data-qa-id="app-pivot">
      <Pivot onLinkClick={_onLinkClick} selectedKey={_selectedPivot()}>
        <PivotItem headerText={viewKey} ariaLabel={viewKey} itemKey={viewKey}>
          <Title title={viewKey} variant={"xLarge"} />
        </PivotItem>
        <PivotItem headerText={downloadKey} ariaLabel={downloadKey} itemKey={downloadKey}>
          <Title title={downloadKey} variant={"xLarge"} />
        </PivotItem>
      </Pivot>
    </div>
  );
};

export default BrowserPivot;
