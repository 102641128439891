import { useCallback, useContext, useEffect } from "react";
import { ChoiceGroup, IChoiceGroupOption, IChoiceGroupStyles } from "@fluentui/react";
import ChoiceItem from "../../components/layout/choice/choiceItem";
import ComponentsList, { IComponent } from "../../components/layout/componentList/componentList";
import { AppContext } from "../../state/appContext";
import React from "react";
import { useParams } from "react-router-dom";
import {
  reset,
  selectAllComponents,
  selectComponent,
  setDownloadView,
  setError,
  setPageLoaded,
  setPageLoading,
  setPageNumber,
  setPageSize,
} from "../../state/appActions";
import { v4 as uuidv4 } from "uuid";
import { ViewType } from "../../models/viewType";
import { Config } from "../../config";
import { ContentType, DownloadContent } from "../../generated";
import Constants from "../../components/Constants";
import { useAccount, useMsal } from "@azure/msal-react";
import {
  downloadGetDownloadContents,
  getAccessToken,
  viewGetObjectDetails,
} from "../../components/common/authentication/appService";
import { LogService } from "@preservica/log-service";

const choiceGroupStyles: IChoiceGroupStyles = {
  label: {
    display: "inline",
  },
  flexContainer: {
    columnGap: "1em",
    display: "inline-flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
};

export interface IComponentsListProps {
  items: IComponent[];
}

// DEV: http://localhost:3000/download/ebd098e8-56b6-44cc-9cb4-a822f566844d/c57687f0-1dae-482a-b894-25cdbd62ab25?P365LogLevel=4
// QA: http://localhost:3000/download/01ce0cc9-91d0-46c5-a336-880459775611/958b9ae2-b098-47a3-8322-da22b74b3554?P365LogLevel=4
const LOG_SOURCE = "Download";
const Download: React.FunctionComponent = () => {
  const { preservicaId } = useParams();
  const { state, dispatch } = useContext(AppContext);

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const options: IChoiceGroupOption[] = [
    {
      key: ViewType.Access.toString(),
      text: Config.DownloadChoices.Access.Title,
      disabled: state.disableLatestAccess,
      onRenderField: (props, render) => {
        return <ChoiceItem render={render} props={props} tooltip={Config.DownloadChoices.Access.Description} />;
      },
    },
    {
      key: ViewType.Latest.toString(),
      text: Config.DownloadChoices.Latest.Title,
      onRenderField: (props, render) => {
        return <ChoiceItem render={render} props={props} tooltip={Config.DownloadChoices.Latest.Description} />;
      },
    },
    {
      key: ViewType.Original.toString(),
      text: Config.DownloadChoices.Original.Title,
      onRenderField: (props, render) => {
        return <ChoiceItem render={render} props={props} tooltip={Config.DownloadChoices.Original.Description} />;
      },
    },
  ];

  const getApiBaseUrl = (): string => {
    // return appClient?.download.httpRequest.config.BASE;
    return Constants.CLIENT_API_BASE_URL ?? "";
  };

  const getSingleItemDownloadUrl = (contentId: string) => {
    return `${getApiBaseUrl()}/v1/download/${preservicaId}/${contentId}/${state.viewType as number as ContentType}`;
  };

  const getMultipleItemsDownloadUrl = (includePropertiesFile: boolean) => {
    return `${getApiBaseUrl()}/v1/download/${preservicaId}/${
      state.viewType as number as ContentType
    }/?includePropertiesFile=${includePropertiesFile}`;
  };

  const getFilenameFromResponseHeaders = (responseHeaders: Headers) => {
    // extract filename from response header
    // e.g. content-disposition: attachment; filename=.image1.png; filename*=UTF-8''.image1.png
    // requires Web API CORS policy to contain .WithExposedHeaders("Content-Disposition")
    const contentDispositionHeader = responseHeaders.get("content-disposition") ?? "";

    if (!contentDispositionHeader) return "0";

    const firstSplit = contentDispositionHeader.split("filename=")[1];
    const secondSplit = firstSplit.split(";");
    const filename = secondSplit[0];
    return filename;
  };

  const getFileContentTypeFromResponseHeaders = (responseHeaders: Headers) => {
    // extract content type from response header
    return responseHeaders.get("content-type") ?? "application/octet-stream";
  };

  const downloadBlob = (blob: Blob, filename: string) => {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); // use inline to open in browser but this will require a mime type
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  const openBlob = (blob: Blob, contentType: string) => {
    const url = window.URL.createObjectURL(new Blob([blob], { type: contentType }));
    window.open(url, "_blank");
    return;
  };

  // NOTE (JIMG): the generated class does not seem to work for file downloads so using fetch()!
  const onDownloadSingleItem = async (selectedComponent: IComponent): Promise<void> => {
    const LOG_MESSAGE = "onDownloadSingleItem";
    LogService.info(LOG_SOURCE, LOG_MESSAGE, undefined, { view: state.viewType, selectedComponent: selectedComponent });

    dispatch(setPageLoading());

    try {
      if (!account) throw Error("Failed to obtain account info");
      const accessToken = await getAccessToken(instance, account);
      if (!accessToken) throw Error("Failed to obtain access token");

      const response = await fetch(getSingleItemDownloadUrl(selectedComponent.id), {
        headers: {
          "X-Correlation-ID": uuidv4(),
          Authorization: `Bearer ${accessToken}`,
        },
      });

      switch (response.status) {
        // success
        case 200: {
          dispatch(setPageLoaded());
          const blob = await response.blob();
          const contentType = getFileContentTypeFromResponseHeaders(response.headers);
          openBlob(blob, contentType);
          break;
        }

        // non-exceptional failures
        default: {
          // the current fail scenario handled here has no response body
          // so no attempt being made to extract contextual error messages
          const errorMessage = `Server responded with ${response.status}`;
          LogService.error(LOG_SOURCE, LOG_MESSAGE, "Error", errorMessage);
          dispatch(setError(errorMessage));
        }
      }
    } catch (reason) {
      // exceptional failures
      LogService.error(LOG_SOURCE, LOG_MESSAGE, "Error", reason);
      dispatch(setError(reason ? reason : {}));
    }
  };

  // NOTE (JIMG): the generated class does not seem to work for file downloads so using fetch()!
  const onDownloadMultipleItems = async (includePropertiesFile: boolean): Promise<void> => {
    const LOG_MESSAGE = "onDownloadMultipleItems";
    LogService.info(LOG_SOURCE, LOG_MESSAGE, undefined, {
      view: state.viewType,
      selectedComponents: state.selectedComponents,
      includePropertiesFile: includePropertiesFile,
    });

    // reduce to get the non empty component ids
    const selectedIds = state.selectedComponents.reduce((prev, curr) => {
      if (curr.id && curr.id !== "") {
        prev.push(curr.id);
      }
      return prev;
    }, [] as string[]);

    dispatch(setPageLoading());

    try {
      if (!account) throw Error("Failed to obtain account info");
      const accessToken = await getAccessToken(instance, account);
      if (!accessToken) throw Error("Failed to obtain access token");

      const response = await fetch(getMultipleItemsDownloadUrl(includePropertiesFile), {
        headers: {
          "Content-Type": "application/json",
          "X-Correlation-ID": uuidv4(),
          Authorization: `Bearer ${accessToken}`,
        },
        method: "POST",
        body: JSON.stringify(selectedIds),
      });

      switch (response.status) {
        // success
        case 200: {
          const filename = `${state.asset?.title}.zip`;
          LogService.info(LOG_SOURCE, LOG_MESSAGE, "filename", filename);
          dispatch(setPageLoaded());

          if (filename) {
            const blob = await response.blob();
            downloadBlob(blob, filename);
          }

          break;
        }

        // non-exceptional failures
        default: {
          // the current fail scenario handled here has no response body
          // so no attempt being made to extract contextual error messages
          const errorMessage = `Server responded with ${response.status}`;
          LogService.error(LOG_SOURCE, LOG_MESSAGE, "Error", errorMessage);
          dispatch(setError(errorMessage));
        }
      }
    } catch (reason) {
      // Fail
      LogService.error(LOG_SOURCE, LOG_MESSAGE, "Error", reason);
      dispatch(setError(reason ? reason : {}));
    }
  };

  const onChangeDownloadFileType = (option?: IChoiceGroupOption): void => {
    const LOG_MESSAGE = "onChangeDownloadFileType";
    LogService.info(LOG_SOURCE, LOG_MESSAGE, undefined, option?.key);

    if (option) {
      const updatedViewType = parseInt(option.key) as ViewType;
      dispatch(setDownloadView(updatedViewType));
    }
  };

  const onChangePage = (updatedPageNumber: number): void => {
    if (updatedPageNumber !== state.pageNumber) {
      const LOG_MESSAGE = "onChangePage";
      dispatch(setPageNumber(updatedPageNumber));
      LogService.info(LOG_SOURCE, LOG_MESSAGE, undefined, updatedPageNumber);
    }
  };

  const onChangePageSize = (updatedPageSize: number): void => {
    if (updatedPageSize !== state.pageSize) {
      const LOG_MESSAGE = "onChangePageSize";
      dispatch(setPageSize(updatedPageSize));
      LogService.info(LOG_SOURCE, LOG_MESSAGE, undefined, updatedPageSize);
    }
  };

  const onSelectItem = (selectedComponent: IComponent): void => {
    dispatch(selectComponent(selectedComponent as DownloadContent));
  };

  const onSelectAll = (): void => {
    dispatch(selectAllComponents());
  };

  const viewAsset = useCallback(viewGetObjectDetails, [account, instance, preservicaId]);

  const downloadAsset = useCallback(downloadGetDownloadContents, [account, instance, preservicaId]);

  useEffect(() => {
    if (preservicaId) {
      if (preservicaId !== state.preservicaId) {
        // only call into the API if tenantId / preservicaId have changed
        if (!state.asset) {
          viewAsset(dispatch, account, instance, preservicaId, LOG_SOURCE, "viewAsset");
        }

        downloadAsset(dispatch, state, account, instance, preservicaId, LOG_SOURCE, "downloadAsset");
      }
    } else {
      dispatch(reset());
    }
  }, [downloadAsset]);

  return (
    <>
      {/* IIFE */}
      {(() => {
        if (state.asset) {
          return (
            <>
              <ChoiceGroup
                styles={choiceGroupStyles}
                selectedKey={state.viewType.toString()}
                options={options}
                onChange={(ev, option) => onChangeDownloadFileType(option)}
                required={true}
              />
              <ComponentsList
                loading={state.pageLoading}
                displayItems={state.pagedItems.map((i) => ({ ...i } as IComponent))}
                itemsTotal={state.viewItems.length}
                downloadItem={onDownloadSingleItem}
                downloadSelectedItems={onDownloadMultipleItems}
                singleItemDownloadUrlProvider={getSingleItemDownloadUrl}
                pageNumber={state.pageNumber}
                pagesTotal={state.pagesTotal}
                pageSize={state.pageSize}
                allComponentsSelected={state.allComponentsSelected}
                selectedComponents={state.selectedComponents.map((i) => ({ ...i } as IComponent))}
                navigateToPage={onChangePage}
                changePageSize={onChangePageSize}
                selectComponent={onSelectItem}
                selectAllComponents={onSelectAll}
              />
            </>
          );
        }
      })()}
    </>
  );
};

export default Download;
