import { Config } from "../config";
import { AppClient, DownloadContent, DownloadContents } from "../generated";
import { IAsset } from "../models/asset";
import { IError } from "../models/error";
import { ViewType } from "../models/viewType";

export interface IAppState {
  error?: IError;
  asset?: IAsset;
  assetTimestamp?: number;
  pageLoading: boolean;
  preservicaId?: string;
  downloadContents?: DownloadContents;
  sameItemsExistForEachViewType: boolean;
  disableLatestAccess: boolean;
  viewType: ViewType;
  viewItems: DownloadContent[];
  pagedItems: DownloadContent[];
  pageNumber: number;
  pagesTotal:number;
  pageSize: number;
  allComponentsSelected: boolean;
  selectedComponents: DownloadContent[];
}

export const initialAppState: IAppState = {
  error: undefined,
  asset: undefined,
  assetTimestamp: undefined,
  pageLoading: false,
  preservicaId: undefined,
  downloadContents: undefined,
  sameItemsExistForEachViewType: false,
  disableLatestAccess: false,
  viewType: ViewType.Access,
  viewItems: [],
  pagedItems: [],
  pageNumber: 1,
  pagesTotal: 1,
  pageSize: Config.Pagination.AttachmentsDefault,
  allComponentsSelected: false,
  selectedComponents: []
}