import { LogService } from "@preservica/log-service";
import { DownloadContent, DownloadContents } from "../generated";
import { ViewType } from "../models/viewType";
import { ActionType, AppActions } from "./appActions";
import { IAppState, initialAppState } from "./appState";

const calculateSameItemsExistForEachViewType = (downloadContents?: DownloadContents): boolean => {
  const accessTitles = downloadContents?.access?.map((i) => i.title) ?? [];
  const latestTitles = downloadContents?.master?.map((i) => i.title) ?? [];
  const originalTitles = downloadContents?.original?.map((i) => i.title) ?? [];

  const uniqueUnion = new Set(accessTitles.concat(latestTitles).concat(originalTitles));
  return [accessTitles.length, latestTitles.length, originalTitles.length].every(
    (length) => length === uniqueUnion.size
  );
};

const getViewTypeTtems = (viewType: ViewType, downloadContents?: DownloadContents): DownloadContent[] => {
  switch (viewType) {
    case ViewType.Access:
      return downloadContents?.access ?? [];
    case ViewType.Latest:
      return downloadContents?.master ?? [];
    case ViewType.Original:
      return downloadContents?.original ?? [];
  }
};

const processStateUpdate = (updatedState: IAppState): IAppState => {
  LogService.verbose(LOG_SOURCE, "UpdatedState", undefined, updatedState);
  return updatedState;
};

const LOG_SOURCE = "appReducer";
export const appReducer = (state: IAppState, action: AppActions): IAppState => {
  // log the current state and the action type
  LogService.verbose(LOG_SOURCE, ActionType[action.type], undefined, {
    state: state,
    action: ActionType[action.type],
    payload: action.payload,
  });

  switch (action.type) {
    case ActionType.Reset:
      return processStateUpdate(initialAppState);

    case ActionType.SetError:
      return processStateUpdate({
        ...state,
        pageLoading: false,
        error: action.payload,
      });

    case ActionType.SetAsset:
      return processStateUpdate({
        ...state,
        asset: action.payload,
        assetTimestamp: Date.now(),
        error: action.payload ? undefined : state.error,
      });

    case ActionType.SetPageLoading:
      return processStateUpdate({
        ...state,
        pageLoading: action.payload,
        error: undefined,
      });

    case ActionType.SetDownloadContents: {
      const disableLatestAccess = action.payload.downloadContents.access?.length === 0;
      const viewType = disableLatestAccess ? ViewType.Latest : initialAppState.viewType;
      const viewItems = getViewTypeTtems(viewType, action.payload.downloadContents);

      return processStateUpdate({
        ...state,
        pageLoading: false,
        preservicaId: action.payload.preservicaId,
        downloadContents: action.payload.downloadContents,
        sameItemsExistForEachViewType: calculateSameItemsExistForEachViewType(action.payload.downloadContents),
        viewType: viewType,
        disableLatestAccess: disableLatestAccess,
        viewItems: viewItems,
        pagedItems: viewItems.slice((state.pageNumber - 1) * state.pageSize, state.pageNumber * state.pageSize),
        pagesTotal: Math.ceil(viewItems.length / state.pageSize),
        error: undefined,
      });
    }

    case ActionType.SetDownloadView: {
      const viewItems = getViewTypeTtems(action.payload, state.downloadContents);

      // map seletced components to the correct view
      const updatedSelectedComponents = viewItems.filter((i) =>
        state.selectedComponents.map((c) => c.title).includes(i.title)
      );

      // clear selected items unless the same items exist for each view
      return processStateUpdate({
        ...state,
        viewType: action.payload,
        pageNumber: 1, // reset paging back to the start
        viewItems: viewItems,
        pagedItems: viewItems.slice(0, state.pageSize),
        selectedComponents: state.sameItemsExistForEachViewType ? updatedSelectedComponents : [],
        allComponentsSelected: state.sameItemsExistForEachViewType ? state.allComponentsSelected : false,
      });
    }

    case ActionType.SetPageNumber:
      return processStateUpdate({
        ...state,
        pageNumber: action.payload,
        pagedItems: state.viewItems.slice((action.payload - 1) * state.pageSize, action.payload * state.pageSize),
      });

    case ActionType.SetPageSize:
      return processStateUpdate({
        ...state,
        pageSize: action.payload,
        pageNumber: 1, // reset paging back to the start
        pagedItems: state.viewItems.slice(0, action.payload),
        pagesTotal: Math.ceil(state.viewItems.length / action.payload),
      });
              
    case ActionType.SelectComponent: {
      // add or remove an item from the selected components
      const updatedSelectedComponents: DownloadContent[] = state.selectedComponents
        .map((i) => i.title)
        .includes(action.payload.title)
        ? state.selectedComponents.filter((i) => i.title !== action.payload.title)
        : state.selectedComponents.concat(action.payload);

      // set 'Select All' if all view components are selected (not just page components)
      return processStateUpdate({
        ...state,
        selectedComponents: updatedSelectedComponents,
        allComponentsSelected:
          updatedSelectedComponents.length > 0 &&
          state.viewItems.map((i) => i.title).every((i) => updatedSelectedComponents.map((i) => i.title).includes(i)),
      });
    }

    case ActionType.SelectAllComponents: {
      const updatedAllComponentsSelected = !state.allComponentsSelected;

      return processStateUpdate({
        ...state,
        selectedComponents: updatedAllComponentsSelected ? state.viewItems : [],
        allComponentsSelected: updatedAllComponentsSelected,
      });
    }

    default:
      return processStateUpdate(state);
  }
};
