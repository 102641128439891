import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { AppClient } from "../../../generated";
import Constants from "../../Constants";
import { LogService } from "@preservica/log-service";
import { AppActions, setAsset, setDownloadContents, setError, setPageLoading } from "../../../state/appActions";
import { v4 as uuidv4 } from "uuid";
import { IAppState } from "../../../state/appState";
import { loginRequest } from "@preservica/auth";

export const viewGetObjectDetails = async (
  dispatch: (value: AppActions) => void,
  account: AccountInfo | null,
  instance: IPublicClientApplication,
  preservicaId: string,
  logSource: string,
  logMessage: string
) => {
  if (!account) throw Error("Failed to obtain account info");
  const accessToken = await getAccessToken(instance, account);
  if (!accessToken) throw Error("Failed to obtain access token");
  const appClient = getAppClient(accessToken);

  LogService.info(logSource, logMessage, undefined, {
    preservicaId: preservicaId,
  });
  dispatch(setError(undefined));
  appClient?.view.getObjectDetails(preservicaId ? preservicaId : "", uuidv4()).then(
    (objectDetails) => {
      // Success
      LogService.info(logSource, logMessage, "objectDetails", objectDetails);
      dispatch(
        setAsset({
          title: objectDetails?.name ?? "",
          description: objectDetails?.description ?? "",
          rendererUrl: objectDetails?.rendererUrl ?? "",
        })
      );
    },
    (reason) => {
      // Fail
      LogService.error(logSource, logMessage, "Error", reason);
      dispatch(setError(reason ? reason.body : {}));
    }
  );
};

export const downloadGetDownloadContents = async (
  dispatch: (value: AppActions) => void,
  state: IAppState,
  account: AccountInfo | null,
  instance: IPublicClientApplication,
  preservicaId: string,
  logSource: string,
  logMessage: string
) => {
  if (!account) throw Error("Failed to obtain account info");
  const accessToken = await getAccessToken(instance, account);
  if (!accessToken) throw Error("Failed to obtain access token");
  const appClient = getAppClient(accessToken);

  LogService.info(logSource, logMessage, undefined, {
    preservicaId: preservicaId,
    pageNumber: state.pageNumber,
    pageSize: state.pageSize,
    contentType: state.viewType,
  });
  dispatch(setPageLoading());
  appClient?.download.getDownloadContents(preservicaId ? preservicaId : "", uuidv4()).then(
    (downloadContents) => {
      // Success
      LogService.info(logSource, logMessage, "downloadContents", downloadContents);
      dispatch(setDownloadContents(preservicaId ? preservicaId : "", downloadContents));
    },
    (reason) => {
      // Fail
      LogService.error(logSource, logMessage, "Error", reason);
      dispatch(setError(reason ? reason : {}));
    }
  );
};

export const getAppClient = (accessToken: string): AppClient =>
  new AppClient({
    BASE: Constants.CLIENT_API_BASE_URL,
    HEADERS: { Authorization: `Bearer ${accessToken}` },
  });

export const getAccessToken = async (
  instance: IPublicClientApplication,
  account: AccountInfo
): Promise<string | undefined> => {
  const msalAuthClientId = instance.getConfiguration().auth.clientId;

  try {
    const response = await instance.acquireTokenSilent({
      ...loginRequest(msalAuthClientId, Constants.MSAL_SCOPE),
      account,
    });
    if (response) {
      return response.accessToken;
    }
  } catch (error) {
    const response = await instance.acquireTokenPopup({
      ...loginRequest(msalAuthClientId, Constants.MSAL_SCOPE),
      account,
    });
    if (response) {
      return response.accessToken;
    }
  }

  return undefined;
};
